import Footer from "./ContentFooter";
import Navbar from "./ContentHeader";

export default function Legal() {
  const items = [
    "A - Adquirir un Certificado de Hospedaje con los Clubes Vacacionales participantes, dentro del plazo de la promoción, el cual puede variar dependiendo del destino y el valor del paquete.",
    "B - Se haya pagado la totalidad del Certificado de Hospedaje, contratado con el Club Vacacional.",
    "C - Registrarse en el sitio web www.flyxom.com, en donde se requieren los siguientes datos: nombre completo, correo electrónico, número de certificado Flyxom, nombre del Hotel que expidió el certificado, para obtener un usuario y contraseña.",
    "D - Con el usuario y contraseña, en el sitio web www.flyxom.com, se deberá dar click en la pestaña correspondiente para obtener el reembolso, en donde además se requerirá se el pase de abordar, el comprobante de compra de boletos de avión, la reservación realizada, el checkout del Hotel correspondiente y proporcionar un número de cuenta para el reembolso.",
    "E - En caso de que falte algún tipo de información o documento, le llegará una notificación en donde se le requerirá subsane dicha omisión para poder hacer el reembolso.",
    "F - Se podrá solicitar un reembolso de hasta por $150.00 (Ciento cincuenta dólares americanos 00/100 USD) dentro de los primeros 12 doce meses seguidos a la compra del Certificado de Hospedaje, y un segundo reembolso hasta por la misma cantidad, siempre y cuando haya solicitado el primer reembolso y haya cumplido con el resto de Términos y Condiciones especificados tanto en el Certificado de Hospedaje como en el Sitio Web www.flyxom.com, mismos que aquí se relacionan también.",
    "G - Una vez transcurridos los plazos anteriormente mencionados la persona que compró el Certificado de Hospedaje y quiera hacer valer su reembolso, no podrá hacerlo, en razón de haber pasado más de 12 meses continuos sin el reclamo del mismo.",
    "H - Estará prohibido que cualquier tercero que no sea la persona que compró el Certificado de Hospedaje, goce de los beneficios de reembolso aquí establecidos.",
    "I - En caso de que la cantidad que se otorgue en reembolso alcance a cubrir el reembolso de la persona que adquirió el Certificado de Hospedaje y quede un excedente, este se podrá aplicar también a su cónyuge únicamente.",
    "J - El reembolso se hará por la cantidad neta del boleto o bien el servicio de transporte aéreo por medio de aerolínea comercial, sin incluir impuestos, y sin contemplar tarifas adicionales como la Tarifa de Uso de Aeropuerto (TUA).",
    "K - El costo por boletos solamente será reembolsado en el caso de que el origen y destino de los vuelos sean tanto el lugar de residencia de la persona que compró el Certificado de Hospedaje, como el de los complejos vacacionales descritos y autorizados en el mismo, así como en las fechas designadas y autorizadas en este.",
    "L - Se deberá realizar la solicitud de reembolso a través de la página de internet: www.flyxom.com siguiendo el procedimiento ahí establecido dentro del cual se deberá incluir una copia del ticket de compra del boleto de avión, pase de abordar, copia del “Certificado de Hospedaje”, así como de la reservación del complejo habitacional destino autorizado con un plazo máximo de 15 (quince) días naturales posteriores a la realización del check out en el complejo vacacional por los beneficios que otorga el “Certificado de Hospedaje”.",
    "M - Para gozar del beneficio del reembolso, no se podrá utilizar ningún otro programa de puntos o beneficios, en el entendido que los beneficios de este programa de reembolsos solo aplicaran en caso de que la totalidad del vuelo haya sido pagada con moneda de curso legal. Así mismo este programa no aplicara en caso de que se haya utilizado un programa de beneficios, puntos o descuentos, distintos al “Certificado de Hospedaje” para alojarse en el complejo vacacional que en este último se establece.",
    "N - El reembolso se realizará en un periodo de 45 (cuarenta y cinco) días naturales posteriores a la solicitud por medio de transferencia bancaria o cheque conforme a la cuenta y datos que indique en la página de internet www.flyxom.com siempre y cuando se cumplan con todas las condiciones previstas en el presente acuerdo.",
    "O - Para obtener el reembolso, también se deberá cubrir con una tarifa de envío equivalente a $20.00 (Veinte dólares americanos 00/100 USD) + I.V.A. por cada transferencia que se realice o cheque que emita.",
    "P - El reembolso se perderá de no haberse utilizado en su respectivo periodo.",
    "Q - Es importante para hacer valer el reembolso, asistir a la presentación de ventas organizada por el Club Vacacional y cubrir las políticas de calificación establecidas por este.",
  ];
  return (
    <>
      <div>
        <Navbar />
        <div className="p-4 flex items-center justify-center w-full relative h-screen bg- bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
          <p className="text-white overflow-y-auto max-h-screen py-8">
            <strong className="text-[#fcab21] text-xl">
              Condiciones de Participación
            </strong>{" "}
            <br /> <br />
            <br /> 
            Para ser elegible
            <span className="text-[#fcab21] font-semibold ">
              {" "}
              para el reembolso de los $300.00 (trescientos dólares americanos
              00/100 USD) por gastos realizados en la adquisición de boletos de
              avión, las condiciones de{" "}
            </span>
            participación se encuentran establecidas en los Términos y
            Condiciones del Sitio Web: www.flyxom.com, los cuales consisten
            principalmente en:
            <ul className="pt-8 ">
              {items.map((item, index) => (
                <li className="py-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
