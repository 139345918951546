import Footer from "./ContentFooter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
//import { getDatabase, ref, get } from "firebase/database";
import { getStorage, ref, uploadBytes } from "firebase/storage";

export default function Claim() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const auth = getAuth();
    console.log(user);

    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log(userData);
    setUserData(userData);
  }, [i18n]);

  const setUserData = (userData) => {
    // Extract the necessary data from userData object
    document.getElementById("userName").value =
      userData.firstName + " " + userData.lastName;
    document.getElementById("membership").value = userData.membership;
    document.getElementById("flyxommembership").value =
      userData.flyxom_membership;
  };

  const handleSubmit = () => {
    // Handle form submission
    const storage = getStorage();

    const fileBoardingPass =
      document.getElementById("boardingpassbutton").files[0];
    const fileNameBoardingPass = fileBoardingPass.name;
    const pathBoardingPass =
      "/Claims/" +
      document.getElementById("userName").value +
      "_" +
      document.getElementById("membership").value +
      "_" +
      document.getElementById("flyxommembership").value +
      "/" +
      "boardingpass_" +
      fileNameBoardingPass;
    const storageRef = ref(storage, pathBoardingPass);

    const fileReservation = document.getElementById("reservationproofbutton")
      .files[0];
    const fileNameReservation = fileReservation.name;
    const pathReservation =
      "/claims/" +
      document.getElementById("userName").value +
      "_" +
      document.getElementById("membership").value +
      "_" +
      document.getElementById("flyxommembership").value +
      "/" +
      "reservation_" +
      fileNameReservation;

    const storageRefRes = ref(storage, pathReservation);

    uploadBytes(storageRef, fileBoardingPass)
      .then((snapshot) => {
        console.log("Boarding pass uploaded successfully!");
        alert("Boarding pass uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });

    uploadBytes(storageRefRes, fileReservation)
      .then((snapshot) => {
        console.log("Reservation uploaded successfully!");
        alert("Reservation uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });

    const promises = [
      uploadBytes(storageRef, fileBoardingPass),
      uploadBytes(storageRefRes, fileReservation),
    ];

    Promise.all(promises).then(() => {
      alert("Claim submitted successfully!");
      navigate("/profile", { replace: true }); // Navigate to UserProfile page
    });
  };

  return (
    <>
      <div className="relative bg-center bg-cover h-screen bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
        <div className="w-full h-[180px] bg-grey-200 bg-gradient-to-b from-gray-900 to-gray-700 border-b-4 border-[#fcab21]">
          <div />
          <div> </div>
        </div>
        <div className="flex items-center justify-center w-full relative h-screen bg-center bg-no-repeat bg-[url('./assets/images/home.jpeg')]">
          <div></div>

          <div className="overflow-y-auto max-h-screen bg-gray-800 bg-blend-multiply bg-opacity-50 shadow-md rounded-lg px-8 py-8 backdrop-filter backdrop-blur-md w-[40rem]">
            <h1 className="text-2xl font-bold text-center mb-4 dark:text-gray-200">
              {t("Claim.text")}
            </h1>
            <div className="flex-1 flex flex-col items-center lg:items-end justify-end px-8 mt-2">
              <div className="flex items-center space-x-4 mt-2">
                <button
                  onClick={() => changeLanguage("es")}
                  className="lg:inline-flex lg:w-auto w-full px-1 py-2 rounded text-white items-center justify-center hover:text-[#fcab21]"
                >
                  ES
                </button>
                <button
                  onClick={() => changeLanguage("en")}
                  className="lg:inline-flex lg:w-auto w-full px-1 py-2 rounded text-white items-center justify-center hover:text-[#fcab21]"
                >
                  EN
                </button>
              </div>
              <button
                onClick={() => navigate("/profile", { replace: true })}
                className="mt-6 mb-4 w-full flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold bg-[#fcab21] hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                {t("Back.text")}
              </button>
            </div>
            <p className="border-b-2 border-gray-500"></p>
            <form action="#">
              <div className="pt-4 mb-4">
                <label
                  htmlFor="userName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("User Name.text")}
                </label>
                <input
                  type="string"
                  id="userName"
                  className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                  placeholder="User Name"
                  readOnly
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="membership"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Membership.text")}
                </label>
                <input
                  type="text"
                  id="membership"
                  className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                  readOnly
                  placeholder="Membership"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="flyxommembership"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Flyxom_Membership.text")}
                </label>
                <input
                  type="text"
                  id="flyxommembership"
                  className="shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                  readOnly
                  placeholder="Flyxom Memebership"
                />
              </div>

              <div className="mb-4 rounded-lg">
                <label
                  htmlFor="identitycard"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("ID.text")}
                </label>
                <input
                  id="identitycardbutton"
                  placeholder="Choose image"
                  accept="image/png,image/jpeg,application/pdf,application/msword,application/msword-document"
                  type="file"
                  className="bg-white shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                />
              </div>

              <div className="mb-4 rounded-lg">
                <label
                  htmlFor="boardingpass"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Boarding_Pass.text")}
                </label>
                <input
                  id="boardingpassbutton"
                  placeholder="Choose image"
                  accept="image/png,image/jpeg,application/pdf,application/msword,application/msword-document"
                  type="file"
                  className="bg-white shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                />
              </div>

              <div className="mb-4 rounded-lg">
                <label
                  htmlFor="ticketpurchase"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Upload ticket Purchase.text")}
                </label>
                <input
                  id="ticketpurchasebutton"
                  placeholder="Choose image"
                  accept="image/png,image/jpeg,application/pdf,application/msword,application/msword-document"
                  type="file"
                  className="bg-white shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                />
              </div>

              <div className="mb-4 rounded-lg">
                <label
                  htmlFor="reservationproof"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Reservation_Proof.text")}
                </label>
                <input
                  id="reservationproofbutton"
                  placeholder="Choose image"
                  accept="image/png,image/jpeg,application/pdf,application/msword,application/msword-document"
                  type="file"
                  className="bg-white shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                />
              </div>
              <div className="mb-4 rounded-lg">
                <label
                  htmlFor="checkout"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  {t("Upload checkout.text")}
                </label>
                <input
                  id="checkoutbutton"
                  placeholder="Choose image"
                  accept="image/png,image/jpeg,application/pdf,application/msword,application/msword-document"
                  type="file"
                  className="bg-white shadow-sm rounded-md w-full px-3 py-2 border-2 focus:outline-none focus:border-[#fcab21] focus:ring-[#fcab21]"
                />
              </div>

              <p className="border-b-2 pt-4 border-gray-500"></p>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
                className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold bg-[#c79810] hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                {t("Send.text")}
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
