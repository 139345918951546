import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import Legal from "./Legal";

export default function Footer() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <div
        id="footer"
        className="bg-gray-900 text-white py-4 md:py-8 border-t-4 border-[#fcab21]"
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center justify-center md:flex-row md:justify-center">
            <div className="text-center md:text-left">
              <NavLink to={"/"}>
                <img
                src={new URL("../assets/images/logo.png", import.meta.url).href}
                alt="Footer Logo"
                className="h-12 mb-2 md:mt-0 md:mb-4 mx-auto"
              />
              </NavLink>
              
              <div className="flex items-center justify-center">
                <NavLink
                  to={"/legal"}
                  className="hover:text-yellow-500 hover:underline pb-2"
                >
                  {" "}
                  Información legal{" "}
                </NavLink>
              </div>
              <p className="text-xs md:text-sm" data-translate="Footer">
                {new Date().getFullYear()} Plataformas Xom {t("Footer.text")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
